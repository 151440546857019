import Vue from 'vue'
import { addQueryVar, formatMoney, mapCartItemNodeToGa4Item, formatPrice } from '@/lib/util'
import debounce from 'lodash/debounce'
import { trigger } from '@/lib/utils'

Vue.component('cart-item', {
  props: {
    node: Object,
    url: String,
    image: String,
    title: String,
    price: Number,
    quantity: Number,
    index: Number,
    type: String,
    variantId: Number,
    optionsWithValues: Array,
    properties: Object,
    sellingPlan: Object,
    addbundle: Array,
    addEmbellishment: Array,
    itemKey: String,
    isMiniCart: Boolean
  },
  data () {
    const product = this.node
    if (this.node.properties) {
      const { _collection } = this.node.properties
      if (_collection) {
        product['collection'] = _collection.split('|')
      }
    }
    return {
      maxQuantity: Number.POSITIVE_INFINITY,
      product: product
    }
  },
  methods: {
    updateQuantity: debounce(function (quantity, isDecrease) {
      this.$store.dispatch('updateItemsQuantity', this.variantsToChange(quantity)).then(() => {
        if (quantity > this.quantity) {
          this.maxQuantity = this.quantity
        }
      })
      if (isDecrease) this.removeFromBagGTM()
      else this.addToBagGTM()
      trigger('update-gwp', document.body)
    }, 450),
    remove () {
      this.$store.dispatch('removeItems', this.variantsToRemove)
      this.removeFromBagGTM(this.quantity)
    },
    addToBagGTM(quantity = 1) {
      const ga4Item = mapCartItemNodeToGa4Item(
        this.product,
        quantity,
        this.index,
        this.isMiniCart ? 'mini_cart' : 'cart',
        this.isMiniCart ? 'Mini Cart' : ' Cart'
      )

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: formatPrice(ga4Item.price * quantity * 100),
          items: [ga4Item],
        },
      })
    },
    removeFromBagGTM(quantity = 1) {
      const ga4Item = mapCartItemNodeToGa4Item(
        this.product,
        quantity,
        this.index,
        this.isMiniCart ? 'mini_cart' : 'cart',
        this.isMiniCart ? 'Mini Cart' : ' Cart'
      )

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'USD',
          value: formatPrice(ga4Item.price * quantity * 100),
          items: [ga4Item],
        },
      })
    },
    updateCartItemStatus () {
      const cartItemStatus = this.$refs.cartItemStatus[0]
      if (!cartItemStatus) {
        return
      }

      cartItemStatus.textContent = `Updated: Quantity: ${this.quantity}, ${this.totalFormattedPrice}`
      cartItemStatus.setAttribute('aria-hidden', false)
      setTimeout(() => {
        cartItemStatus.setAttribute('aria-hidden', true)
      }, 1000)
    },
    editBundleProduct () {
      let newUrl = ''
      let key = []
      const results = {}
      const bundleTypes = []
      this.addbundle.map(item => {
        bundleTypes.push(item.properties.purchase_type)
        results[item.properties.purchase_type] = results[item.properties.purchase_type] ? results[item.properties.purchase_type] : []
        for (let i = 0; i < item.quantity; i++) {
          if (item.variant_id) {
            results[item.properties.purchase_type].push(item.variant_id)
            key.push(item.key)
          }
        }
      })
      const productTypes = [...new Set(bundleTypes)]
      productTypes.map(type => {
        if (results[type]) {
          const params = `${results[type].join(',')}&bundletype=${type}&bundlekey=${key}`
          newUrl = addQueryVar(type, params, newUrl)
        }
      })
      return newUrl
    },
    createArrayVariants (items, quantity = 0) {
      return items.reduce((accumulator, addon) => {
        accumulator.push({
          id: addon.key,
          quantity: quantity
        })
        return accumulator
      }, [])
    },
    variantsToChange (quantity = 0) {
      const list = []
      if (!this.addEmbellishment || !this.addEmbellishment.length) {
        list.push({
          id: this.itemKey,
          quantity
        })
        return list
      }
      if (this.addEmbellishment.length) {
        return this.createArrayVariants(this.addEmbellishment, quantity)
      }
    }
  },
  computed: {
    getFreeGiftDescription() {
      return this.properties._free_gift_message || ''
    },
    isFreeGift () {
      return this.properties._is_free_gift_update === 'true'
    },
    isGwp () {
      return this.properties._gwp_nc === '1' || this.properties._gift_for_purchase === 'true'
    },
    isGwpCheckout () {
      return this.properties._gwp_checkout ? this.properties._gwp_checkout === 'true' : false
    },
    variantsToRemove () {
      const list = []
      if ((!this.addbundle || !this.addbundle.length) && (!this.addEmbellishment || !this.addEmbellishment.length)) {
        list.push({
          id: this.itemKey,
          quantity: 0
        })

        return list
      }

      if (this.addbundle.length) {
        return this.createArrayVariants(this.addbundle)
      }

      if (this.addEmbellishment.length) {
        return this.createArrayVariants(this.addEmbellishment)
      }
    },
    formattedPrice () {
      if (!this.properties) {
        return formatMoney(this.price)
      }
      let price = this.properties.bundle_price
        ? formatMoney(this.properties.bundle_price)
        : formatMoney(this.price)
      if (this.addEmbellishment.length) {
        price = formatMoney(this.addEmbellishment.reduce((total, product) => {
          return total + product.price
        }, 0))
      }
      return price
    },
    isGiftCard () {
      return this.type.toLowerCase() === 'gift card'
    },
    totalFormattedPrice () {
      if (!this.properties) {
        return formatMoney(this.price * this.quantity)
      }
      let totalPrice = this.properties.bundle_price
        ? formatMoney(this.properties.bundle_price)
        : formatMoney(this.price * this.quantity)
      if (this.addEmbellishment.length) {
        totalPrice = formatMoney(this.addEmbellishment.reduce((total, product) => {
          return total + product.price * product.quantity
        }, 0))
      }
      return totalPrice
    },
    purchaseTypeText () {
      if (!this.properties) {
        return
      }

      if (this.properties.purchase_type === 'onetime') {
        return 'One-time'
      } else if (this.properties.purchase_type === 'autodeliver') {
        return 'Subscription'
      }
    },
    deliveryText () {
      if (
        this.properties &&
        this.properties.shipping_interval_frequency &&
        this.properties.shipping_interval_unit_type
      ) {
        return `
          Delivery Frequency:
          <br>
          ${this.properties.shipping_interval_frequency} ${this.properties.shipping_interval_unit_type}
        `
      }
    },
    imageSrc () {
      if (!this.properties) {
        return this.image
      }

      return this.properties.bundle_image
        ? this.properties.bundle_image
        : this.image
    },
    isAddOnEmbellishment () {
      if (this.properties && this.type.toLowerCase() === 'embellishment' && this.properties._groupID) {
        return true
      } else {
        return false
      }
    },
    isBundle () {
      if (!this.properties || !this.properties.bundle_index) {
        return true
      }

      return this.properties.bundle_index === 0
    },
    permalink () {
      if (!this.properties) {
        return this.url
      }

      return this.properties.bundle_handle
        ? `/products/${this.properties.bundle_handle}${this.editBundleProduct()}`
        : this.url
    },
    variantTitle () {
      return this.optionsWithValues.reduce((str, option) => {
        if (str.length > 0) {
          str += ' • '
        }
        str += option.name + ': ' + option.value
        return str
      }, '')
    }
  },
  watch: {
    'quantity' () {
      this.updateCartItemStatus()
    }
  },
  created () {
    this.editBundleProduct()
  }
})
